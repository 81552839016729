export class Helper {
  base64ToPdf(d) {
    var base64str = d;
    var binary = atob(base64str.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    var blob = new Blob([view], { type: "application/pdf" });
    var url = URL.createObjectURL(blob);
    return url
  }

  base64ToXlsx(d, filename) {
    var contentType = 'application/vnd.ms-excel'

    let sliceSize = 512;

    var byteCharacters = atob(d);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    var url = URL.createObjectURL(blob);
    // return url;
    this.save(url, filename)
  }

  save(url, filename) {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  segment(key) {
    switch (key) {
      case 'MR' || 'CR':
        return segment_other
        break;
      case 'MG':
        return segment_masto
        break;
      case 'CT':
        return segment_tomo
        break;
      default:
        break;
    }
  }

  layouts(key) {
    switch (key) {
      case 'CR':
        return 1
        break;
      case 'MG':
        return 2
        break;
      case 'CT':
        return 4
        break;
      case 'MR':
        return 4
        break;
      case 'OT':
        return 4
        break;
      case 'RF':
        return 4
        break;
      case 'US':
        return 1
        break;
      default:
        break;
    }
  }


}


const segment_other = [
  { wc: 20, ww: 40 },
  { wc: 80, ww: 160 },
  { wc: 320, ww: 640 },
  { wc: 640, ww: 1280 },
  { wc: 2560, ww: 5120 },
]


const segment_masto = [
  { wc: 1875, ww: 3750 },
  { wc: 3750, ww: 7500 },
  { wc: 15000, ww: 30000 }
]

const segment_tomo = [
  { name: 'CT Lungs', wc: -400, ww: 1500 },
  { name: 'CT Abdomen', wc: 60, ww: 400 },
  { name: 'CT Angio', wc: 300, ww: 600 },
  { name: 'CT Bone', wc: 300, ww: 1500 },
  { name: 'CT Brian', wc: 40, ww: 80 },
  { name: 'CT Chest', wc: 40, ww: 400 },
]

