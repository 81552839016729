import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable ,throwError} from 'rxjs';

@Injectable()
export class HttpServiceInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers;
    let _idUser

    if ((request.body instanceof FormData)) {
      if(sessionStorage.getItem('_user')){
        _idUser = JSON.parse(sessionStorage.getItem('_user'))._id
        headers = request.headers
        .set('user',_idUser)
        .set('Authorization', `Bearer ${sessionStorage.getItem('_tkn')}`);
      }
    } else {
      if(sessionStorage.getItem('_user')){
      _idUser = JSON.parse(sessionStorage.getItem('_user'))._id
      headers = request.headers
        .set('Content-Type', 'application/json')
        .set('user',`${_idUser}`)
        .set('Authorization', `Bearer ${sessionStorage.getItem('_tkn')}`);
      }
    }

    const cloneReq = request.clone({ headers });

    return next.handle(cloneReq)
  }


  manageError(error: HttpErrorResponse) {
    console.log('Sucedió un error', error);
    if(error && error.error.message == 'TokenExpiredError') 
    if(error && error.status == 401 || error && error.error.message == 'jwt expired')
      return throwError({ error : 401, message : 'jwt expired' });
    else if(error && error.status == 409)
      return throwError({ error: 'No se encontro el servicio solicitado' });
    else return throwError(error);
  }
}
