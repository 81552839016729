import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardGuard } from './core/services/auth-guard.guard';
import { ScheduleTicketComponent } from './modules/application/schedule/components/schedule-ticket/schedule-ticket.component';


const routes: Routes = [
  {
    path:'app/access',
    loadChildren:()=>import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path:'paciente',
    loadChildren: () => import('./modules/application-public/application-public.module').then(m => m.ApplicationPublicModule)
  },
  {
    path:'app',
    loadChildren : ()=> import('./modules/application/application.module').then(m => m.ApplicationModule),
    canActivate: [ AuthGuardGuard ]
  },
  {
    path: '**', pathMatch: 'full',
    redirectTo : 'app'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
