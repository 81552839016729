import { Action } from '@ngrx/store'
import { Patient } from '../../core/models/patients'

export const ADD_PATIENT = 'add patient'


export class AddPatient implements Action {
    readonly type = ADD_PATIENT
    constructor(public payload : Patient){}
}

export type Actions = AddPatient