import { Patient } from '../../core/models/patients'
import * as PatientActions from './patients.actions'
import { Action } from 'rxjs/internal/scheduler/Action'

const initialState : Patient = { }

export function patientReducer(state: Patient[ ] = [initialState],action : PatientActions.Actions ){
    switch(action.type){
        case PatientActions.ADD_PATIENT:
            return[...state, action.payload];
        default:
            return state 
    }
}  