import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import * as moment from 'moment';
import { HttpService } from 'src/app/core/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public loggedIn = new BehaviorSubject<boolean>(false);

  private API: string = environment.api
  private data: any = null
  private isProduction: Boolean = environment.production

  constructor(
    public router: Router,
    private http: HttpClient,
    private _http : HttpService
  ) { }

  get isLoggedIn() {
    let loggedIn = sessionStorage.getItem('logged') == 'true' ? true : false;
    this.loggedIn.next(loggedIn);
    return this.loggedIn.asObservable();
  }

  SIGN_IN(data): Observable<any> {
    console.log(this.isProduction)
    console.log(this.API)
    return this.http

      .post(/**${this.API}*/`${(this.isProduction) ? '/api/login/signin' : this.API + '/api/login/signin'}`, data)
      .pipe(catchError(this.handleError))
  }

  SET_SESSION(data) {
    if (data.user.session) {
      let configSession = {
        time: data.user.session.time,
        type: (data.user.session.type == 'min') ? 'm' : 'h'
      }
      let thisMoment = moment().format()
      let expitreIn = moment(thisMoment).add(configSession.time, configSession.type).format()
      sessionStorage.setItem('expireIn', expitreIn)

    }

    sessionStorage.setItem('logged', data.success)
    sessionStorage.setItem('_user', JSON.stringify(data.user))
    sessionStorage.setItem('_tkn', data.token)
    this.loggedIn.next(true);

  }

  UPDATE_SESSION(userid)  {
    this._http.HTTP_PUT('/api/update-sesion', { _id: userid }).subscribe(res=>{
      
      if(res.success && res.session_update.is_active){
        let user = res.session_update
        let configSession = {
          time: user.session.time,
          type: (user.session.type == 'min') ? 'm' : 'h'
        }
        let thisMoment = moment().format()
        let expitreIn = moment(thisMoment).add(configSession.time, configSession.type).format()
        sessionStorage.setItem('expireIn', expitreIn)
      }else {
        this.DROP_SESSION()
      }
  

    })
    
 


  }

  DROP_SESSION() {
    sessionStorage.removeItem('logged')
    sessionStorage.removeItem('_user')
    sessionStorage.removeItem('_tkn')
    sessionStorage.removeItem('expireIn')
    this.loggedIn.next(false);
    this.router.navigate(['app','access'])
    window.location.reload();
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error)
    if (error && error.status == 401)
      return throwError({ error: 'Tu sesión ha expirado o no tienes permisos para realizar esta acción.' });
    else if (error && error.status == 404)
      return throwError({ error: 'No se encontro el servicio solicitado' });
    else if (error && error.status == 500)
      return throwError({ error: 'Ocurrió un problema con el servidor.' });
    else
      return throwError(error);
  }

}
