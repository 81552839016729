import { Component } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'imagen-web';


  ngOnInit():void{
    this.checkSession()
  }
  checkSession(){
    let expire = sessionStorage.getItem('expireIn')
    if(expire){
      // console.log(expire);  
    }else{
    
    }

  }
}
