import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, take, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  // private server : strung = envi
  private API: string = environment.api;
  private data: any = null
  private isProduction : Boolean = environment.production

  public loggedIn = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, public router: Router) { }

  get isLoggedIn() {
    let loggedIn = sessionStorage.getItem('logged') == 'true' ? true : false;
    this.loggedIn.next(loggedIn);
    return this.loggedIn.asObservable();
  }


  HTTP_POST(endpoint, data): Observable<any> {
    return this.http
      .post(/**${this.API}*/`${(this.isProduction) ? endpoint : this.API + endpoint }`, data)
      .pipe(catchError(this.handleError))
  }

  HTTP_PUT(endpoint, data): Observable<any> {
    return this.http
      .put(/**${this.API}*/`${(this.isProduction) ? endpoint : this.API + endpoint }`, data)
      .pipe(catchError(this.handleError))
  }

  HTTP_GET(endpoint, params?): Observable<any> {
    
    return this.http
      .get(/**${this.API}*/`${(this.isProduction) ? endpoint : this.API + endpoint }`, { params: params })
      .pipe(catchError(this.handleError))
  }

  HTTP_DELETE(endpoint, params?): Observable<any> {
    
    return this.http
      .delete(/**${this.API}*/`${(this.isProduction) ? endpoint : this.API + endpoint }`, { params: params })
      .pipe(catchError(this.handleError))
  }

 

  private handleError(error: HttpErrorResponse) {
    if (error && error.status == 401)
      return throwError({ error: 'Tu sesión ha expirado o no tienes permisos para realizar esta acción.' });
    else if (error && error.status == 404)
      return throwError({ error: 'No se encontro el servicio solicitado' });
    else if (error && error.status == 500)
      return throwError({ error: 'Ocurrió un problema con el servidor.' });
    else if (error && error.status == 409){
      sessionStorage.removeItem('logged')
      sessionStorage.removeItem('_user')
      sessionStorage.removeItem('_tkn')
      sessionStorage.removeItem('expireIn')
      window.location.reload();
      this.router.navigate(['app','access'])
    }
    else
      return throwError(error);
  }



}
