// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // api: 'http://innovapacs.hospital'
  api:'https://isem.disenodesalud.com.mx',
   //api: 'http://localhost:5000',
   pacs:'imgmex.disenodesalud.com.mx/InnovaPacs/',

    recaptcha: {
      siteKey: '6Lfr66ceAAAAAO-U8XZxhaYRza2STWq2vX4KpYu8',
    },
    local:false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
