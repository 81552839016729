import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpService } from './core/services/http.service';
import { AuthService } from './modules/auth/auth.service';
import { Helper } from './core/helpers';
// import { UploadDirective } from './shared/directives/upload.directive';
import { StoreModule } from '@ngrx/store'
import { patientReducer } from './store/patients/patients.reducer'
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpServiceInterceptor } from './core/interceptor/http-service.interceptor';


@NgModule({
  declarations: [
    AppComponent,
    // UploadDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot({patients:patientReducer}),
    StoreDevtoolsModule.instrument({maxAge: 25 }),
    
  ],
  providers: [
    HttpService,
    AuthService,
    Helper,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpServiceInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
